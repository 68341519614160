import Header from "../Header";
import Sidebar from "../Sidebar";
import { FormattedMessage } from "react-intl";
import ShopifySvg from "../../assets/svg/ShopifySvg";
import { getDateDifference, getTrialChecks, hasPlatformFeature } from '../../utils'
import { TrailEndHeader } from '../Header/TrialEndHeader'
import {
  IconLayout,
  IconSpeakerphone,
  IconUser,
  IconTicket,
  IconFileAnalytics,
  IconFolder,
  IconListCheck,
  IconDroplet,
  IconSend,
  IconCornerUpLeftDouble,
  IconAdjustmentsHorizontal,
  IconBolt,
  IconClipboardList,
} from "@tabler/icons";
import IconTags from "./IconTags";
import IconPlaylistAdd from "./IconPlaylistAdd";

export const Layout = ({ isShopify, isWix, children, show = true, siteId, site, userId }) => {

  const { entitlements, isSiteOwner } = site || {}
  const { trialExpiresAt, siteOwnerCreatedAt: createdAt } = entitlements || {}
  const daysLeft = getDateDifference(trialExpiresAt);
  const totalTrialDays = getDateDifference(trialExpiresAt, createdAt)
  const isMobile = document.body.clientWidth < 600;
  const showTrialHeader = getTrialChecks(site) && siteId && !isMobile
  const sideBarHeight = `calc(100vh - ${showTrialHeader ? '112px' : '64px'})`;

  const navigation = [
    ...(
      hasPlatformFeature('dashboard') ? [{
        label: <FormattedMessage id='main.sidebar.home' defaultMessage='Home' />, 
        href: `/sites/${siteId}/dashboard`, 
        icon: <IconLayout stroke={1.5} size={24}/>, 
        selected: false 
      }] : []
    ),
    {
      label: <FormattedMessage id='main.sidebar.campaigns.title' defaultMessage='Campaigns' />,
      href: `/sites/${siteId}/campaigns`,
      icon: <IconSpeakerphone stroke={1.5} size={24}/>,
      selected: false,
      subMenu: [
        { 
         label: <FormattedMessage id='main.sidebar.campaigns.all' defaultMessage='ALL' />,
         href: "#", 
         type:'all',
         icon: <IconFolder stroke={1.5} size={20}/>, 
         selected: true 
        },
        ...(
          hasPlatformFeature('widgets') ? [{
            label: <FormattedMessage id='main.sidebar.campaigns.forms' defaultMessage="Forms & popups" />,
            href: "#",
            type:'widget',
            icon: <IconListCheck stroke={1.5} size={20}/>,
            selected: false,
          }] : []
        ),
        ...(window?.isShopify && hasPlatformFeature("shopify_pages") ? [{
          label: <FormattedMessage id='main.sidebar.campaigns.shopify_pages' defaultMessage="Shopify Pages" />,
          type:'shopify_page',
          href: "#",
          icon: <ShopifySvg size="20px" hasCustomStyles={true} />,
          selected: false,
        }] : []),
        ...(
          hasPlatformFeature('landing_pages') ? [{
            label: <FormattedMessage id='main.sidebar.campaigns.landing_pages' defaultMessage="Landing Pages"/>,
            type:'landing_page',
            href: "#",
            icon: <IconLayout stroke={1.5} size={20}/>,
            selected: false,
          }] : []
        ),
        ...(
          hasPlatformFeature('autoresponders') ? [{
            label: <FormattedMessage id='main.sidebar.campaigns.auto_responders' defaultMessage="Autoresponders" />,
            type:'welcome_email',
            href: "#",
            icon: <IconCornerUpLeftDouble stroke={1.5} size={20}/>,
            selected: false,
          }] : []
        ),
        ...(
          hasPlatformFeature('broadcasts') ? [{
            label: <FormattedMessage id='main.sidebar.campaigns.broadcasts' defaultMessage="Broadcasts" />, 
            href: "#", 
            type:'broadcast_email', 
            icon: <IconSend stroke={1.25} size={20}/>, 
            selected: false 
          }] : []
        ),
        ...(
          hasPlatformFeature('drips') ? [{
            label: <FormattedMessage id='main.sidebar.campaigns.drips' defaultMessage="Drip Sequences" />,
            type:'drip_campaign',
            href: "#",
            icon: <IconDroplet stroke={1.5} size={20}/>,
            selected: false,
          }] : []
        ),
        ...(
          hasPlatformFeature('automations') ? [{
            label: <FormattedMessage id='main.sidebar.campaigns.automations' defaultMessage="Automations" />,
            type:'automation',
            href: "#",
            icon: <IconBolt stroke={1.5} size={20}/>,
            selected: false,
          }] : []
        )
      ],
    },
    ...(
      hasPlatformFeature('subscribers') ? [{
        label: <FormattedMessage id='main.sidebar.contacts' defaultMessage="Contacts" />, 
        href: `/sites/${siteId}/contacts`, 
        icon: <IconUser stroke={1.5} size={24}/>, 
        selected: false,
        subLinks: ['lists', 'tags', 'custom_fields'],
        subMenu: [
          ...(
            hasPlatformFeature('subscribers', 'lists') ? [{
              label: <FormattedMessage id="page.contacts.dropdowns.audience-settings.options.list" defaultMessage="List" />,
              href: `/sites/${siteId}/lists`,
              icon: <IconClipboardList stroke={1.5} size={24}/>, 
              selected: false
            }] : []
          ),
          ...(
            hasPlatformFeature('subscribers', 'tags') ? [{
              label: <FormattedMessage id="page.contacts.dropdowns.audience-settings.options.tags" defaultMessage="Tags" />,
              href: `/sites/${siteId}/tags`,
              icon: <IconTags/>, 
              selected: false
            }] : []
          ),
          ...(
            hasPlatformFeature('subscribers', 'custom_fields') ? [{
              label: <FormattedMessage id="page.contacts.dropdowns.audience-settings.options.custom-fields" defaultMessage="Custom Fields" />,
              href: `/sites/${siteId}/custom_fields`,
              icon: <IconPlaylistAdd stroke={1.5} size={24}/>, 
              selected: false
            }] : []
          ),
        ]
      }] : []
    ),
    ...((isShopify || (isWix && window.isBetaUser) || window.isAvb || false) && hasPlatformFeature('coupons') ? [{ 
      label: <FormattedMessage id='main.sidebar.coupons' defaultMessage="Coupons" />, 
      href:`/sites/${siteId}/coupon_codes`, 
      icon: <IconTicket stroke={1.5} size={24}/>, 
      selected: false 
    }] : []),
    ...(
      hasPlatformFeature('analytics') ? [{
        label: <FormattedMessage id='main.sidebar.analytics' defaultMessage="Analytics" />,
        href: `/sites/${siteId}/analytics`,
        icon: <IconFileAnalytics stroke={1.5} size={24}/>,
        selected: false,
      }] : []
    )
  ];
  
  const footerNavigation = [
    ...(
      hasPlatformFeature("sites", "general_settings") ? [{
        label: <FormattedMessage id='main.sidebar.site_settings' defaultMessage="Site Settings" />,
        href: `/sites/${siteId}/settings`,
        icon: <IconAdjustmentsHorizontal stroke={1.5} size={24}/>,
        selected: false,
        path: "/settings"
      }] : []
    )
  ];

  return (
    <>
    {
      show ?
      <div>
        <Header siteId={siteId} />
        <div
          style={{ height: sideBarHeight }}
          className="flex overflow-hidden bg-gray-100"
        >
          {hasPlatformFeature('app_layout', 'side_navigation') && 
            <Sidebar 
              navigation={navigation} 
              footerNavigation={footerNavigation}
            />}

          {/* Rest of the area code */}
          <div className="w-full overflow-scroll h-full relative" data-attribute="product-app-layout-container">
            <div className="w-[1113px] px-[10px] h-full mx-auto pt-[32px]">
              {children}
            </div>
          </div>
        </div>
      </div> :
      <>
      {
      showTrialHeader && hasPlatformFeature("billing", "account") &&
      <TrailEndHeader daysLeft={daysLeft} totalTrialDays={totalTrialDays} isSiteOwner={isSiteOwner} />
      }
      {children}
      </>
    }
    </>
  );
};
